<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form
              ref="form"
              v-model="form.valid"
              lazy-validation
              @submit.prevent="submitForm"
            >
              <div class="row">
                <!-- <div class="col-3">
                  <v-select
                    v-model="form.memberClass"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.GENERATE.MEMBER_CLASS')"
                    :clearable="true"
                    :items="form.memberClassItems"
                    :rules="form.memberClassRules"
                    required
                  ></v-select>
                </div> -->
                <!-- <div class="col-3">
                  <v-text-field
                    v-model="form.quantity"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.GENERATE.QUANTITY')"
                    :rules="form.quantityRules"
                    required
                    type="number"
                    min="0"
                  ></v-text-field>
                </div> -->
                <div class="col-3">
                  <v-text-field
                    v-model="form.generateRefCode"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.MANAGE.GENERATE_REF_CODE')"
                    :rules="form.generateRefCodeRules"
                    counter="50"
                    maxlength="50"
                    required
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-dialog
                    ref="dialog"
                    v-model="form.expireMonthModal"
                    :disabled="form.loading"
                    :return-value.sync="form.expireMonth"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.expireMonth"
                        :disabled="form.loading"
                        :label="$t('MEMBER_CARD.MANAGE.EXPIRE_MONTH')"
                        :rules="form.expireMonthRules"
                        required
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.expireMonth"
                      type="month"
                      locale="th"
                      :disabled="form.loading"
                      :min="minExpireMonth"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.expireMonthModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.expireMonth)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>
                <!-- <div class="col-3">
                  <b>{{ $t("MEMBER_CARD.GENERATE.EXAMPLE") }}</b>
                  &nbsp;
                  <span>ST</span>
                  <span class="kt-font-bolder">{{ numMemberClass }}</span>
                  <span
                    ><u>{{ numExpireMonth }}</u></span
                  >
                  <span>000000</span>
                </div> -->
                <div class="col-3">
                  <v-file-input
                    accept="image/*"
                    v-model="form.cardPictureFront"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_FRONT')"
                    :rules="form.cardPictureFrontRules"
                    :hint="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_FRONT_HINT')"
                    required
                  ></v-file-input>
                  <template v-if="form.cardPictureFront">
                    <br />
                    <img :src="cardPictureFrontBase64" style="width: 100%;" />
                  </template>
                </div>
                <div class="col-3">
                  <v-file-input
                    accept="image/*"
                    v-model="form.cardPictureBack"
                    :disabled="form.loading"
                    :label="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_BACK')"
                    :rules="form.cardPictureBackRules"
                    :hint="$t('MEMBER_CARD.MANAGE.CARD_PICTURE_BACK_HINT')"
                    required
                  ></v-file-input>
                  <template v-if="form.cardPictureBack">
                    <br />
                    <img :src="cardPictureBackBase64" style="width: 100%;" />
                  </template>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                   
                  >
                    <v-icon v-if="!form.loading" left>la-save</v-icon>
                    <v-icon v-if="form.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </div>
                  

              <v-dialog v-model="form.dialog" persistent max-width="300">
                    <v-card>
                      <v-card-title class="headline">
                        {{
                          $t("MEMBER_CARD.MANAGE.CONFIRM_DIALOG_HEADER")
                        }}</v-card-title
                      >
                      <v-card-text>
                        <template v-if="!form.loading">
                          {{ $t("MEMBER_CARD.MANAGE.CONFIRM_DIALOG_TEXT") }}
                        </template>
                        <template v-else>
                          <div class="text-center">
                            <v-icon>fa fa-spinner fa-spin</v-icon>
                            {{ $t("SHARED.PROCESSING") }}
                          </div>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          :disabled="form.loading"
                          color="grey darken-1"
                          text
                          @click="form.dialog = false"
                          >{{ $t("SHARED.RECHECK_BUTTON") }}
                        </v-btn>
                        <v-btn
                          :disabled="form.loading"
                          color="green darken-1"
                          text
                          @click="closeDialog"
                          
                          >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                        > 
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                
                 <!-- <v-dialog v-model="form.downloading" persistent width="300">
                    <v-card>
                      <v-card-title class="headline">
                        {{
                          $t("MEMBER_CARD.GENERATE.DOWNLOAD_DIALOG_HEADER")
                        }}</v-card-title
                      >
                      <v-card-text>
                        {{ $t("MEMBER_CARD.GENERATE.DOWNLOAD_DIALOG_TEXT") }}
                        <br />
                        BATCH ID: {{ form.downloadingBatchId }}
                        <br />
                        <a
                          target="_batch"
                          href="#"
                          @click.prevent="openDownloadWindow"
                          >{{ $t("SHARED.DOWNLOAD_BUTTON") }}</a
                        >
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="grey darken-1"
                          text
                          @click="closeDialog"
                          >{{ $t("SHARED.CLOSE_BUTTON") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import moment from "moment";

import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      cardPictureFrontBase64: null,
      cardPictureBackBase64: null,
      form: {
        valid: true,
        loading: false,
        dialog: false,
        downloading: false,
        downloadingBatchId: null,
        errors: [],
        expireMonth: null,
        expireMonthModal: false,
        expireMonthRules: [
          (v) => !!v || this.$t("MEMBER_CARD.MANAGE.EXPIRE_MONTH_REQUIRED"),
        ],
        generateRefCode: "",
        generateRefCodeRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_REQUIRED"),
          (v) =>
            (v && v.length >= 10) ||
            this.$t("MEMBER_CARD.MANAGE.GENERATE_REF_CODE_MIN_10_CHAR"),
        ],
        cardPictureFront: null,
        cardPictureFrontRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.MANAGE.CARD_PICTURE_FRONT_REQUIRED"),
        ],
        cardPictureBack: null,
        cardPictureBackRules: [
          (v) =>
            !!v || this.$t("MEMBER_CARD.MANAGE.CARD_PICTURE_BACK_REQUIRED"),
        ],
      },
    };
  },
  methods: {
    // openDownloadWindow() {
    //   window.open(
    //     "/Files/Download/MemberCard/" +
    //       this.form.downloadingBatchId +
    //       "?token=" +
    //       JwtService.getToken(),
    //     "_blank"
    //   );
    // },
    // closeDownloadDialog() {
    //   // not close but redirect to search page
    //   this.$router.push({ name: "SearchMemberCards" });
    // },
    getFileBase64(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve({
            url: reader.result,
            base64: reader.result.split(",")[1],
            contentType: file.type.split("/")[1],
          });
        };
        reader.readAsDataURL(file);
      });
    },
    getImageMetadata(file) {
      return new Promise((reslove) => {
        let image = new Image();
        image.onload = () => {
          reslove({
            w: image.width,
            h: image.height,
          });
        };
        image.src = file;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.postDataToApi()
      }
    },
    async postDataToApi() {
      // this.form.loading = true;
      this.form.errors = [];
      

      var cardFrontBase64 = await this.getFileBase64(this.form.cardPictureFront);
      var cardBackBase64 = await this.getFileBase64(this.form.cardPictureBack);

      ApiService.setHeader();
      ApiService.post("/Api/MemberCards/Add", {
        expireDateString: moment(this.form.expireMonth, "YYYY-MM HH:mm:ss")
          .endOf("month")
          .format("DD/MM/YYYY 23:59:59"),
        generateRefCode: this.form.generateRefCode,
        cardFrontBase64: cardFrontBase64.base64,
        cardFrontContentType: cardFrontBase64.contentType,
        cardBackBase64: cardBackBase64.base64,
        cardBackContentType: cardBackBase64.contentType,
      })
        .then(({ data }) => {
          if (data.status) {
            // close confirm dialog
            this.form.dialog = true;
            // force download excel
            this.form.downloading = true;
            this.form.downloadingBatchId = data.data;
          } else {
            this.form.loading = false;
            this.form.dialog = false;
            this.form.errors.push(!!data.message || "Unknow error occurs");
            this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          })
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.message);
          } else {
            this.form.errors.push("Unknown error occurs");
          }
          this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          });
          this.form.loading = false;
          this.form.dialog = false;
        });
    },
    closeDialog() {
      this.$router.push({ name: "ManageMemberCards" });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.MEMBER_CARD.SECTION"), route: "/MemberCards" },
      { title: this.$t("MENU.MEMBER_CARD.ADD") },
    ]);
  },
  watch: {
    "form.cardPictureFront"(newValue) {
      this.cardPictureFrontBase64 = null;
      if (newValue) {
        this.getFileBase64(this.form.cardPictureFront).then((file) => {
          this.cardPictureFrontBase64 = file.url;
        });
      }
    },
    "form.cardPictureBack"(newValue) {
      this.cardPictureBackBase64 = null;
      if (newValue) {
        this.getFileBase64(this.form.cardPictureBack).then((file) => {
          this.cardPictureBackBase64 = file.url;
        });
      }
    },
  },
  computed: {
    title() {
      return this.$t("MENU.MEMBER_CARD.ADD");
    },
    minExpireMonth() {
      return new Date().toISOString().substr(0, 7);
    },
    // numMemberClass() {
    //   return this.form.memberClass == "MEMBER_CLASS_GOLD" ? "G" : "S";
    // },
    numExpireMonth() {
      return this.form.expireMonth
        ? this.form.expireMonth.substr(2, 2)
        : new Date().toISOString().substr(2, 2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
